<template>
  <div class="section">
    <div class="container">
      <div class="main-container">
        <div class="content">
          <div class="title">
            全平台游戏库
            <br />
            游戏信息全掌握
          </div>
          <div class="tip">游戏价格、攻略、奖杯，看Jump就够了</div>
        </div>
        <div class="product">
          <div class="history">
            <img v-lazy="history" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      history:
        'http://switch-cdn.vgjump.com/img/mobile/offical-20200116-zj-01-game.png',
    }
  },
}
</script>
<style scoped lang="scss">
.section {
  padding: 8vw 0;
}
.container {
  max-width: 92.5333vw;
}
.main-container {
  margin: auto;
  max-width: 82.8vw;
  .content {
    width: 100%;
    .title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 6.4vw !important;
      line-height: 8.064vw !important;
      text-align: center;
      color: rgba(0, 0, 0, 0.8);
    }
    .tip {
      margin-top: 2.667vw;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 4.2667vw;
      line-height: 5.333vw;
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
.product {
  .history {
    margin-top: 6.667vw;
    width: 74.8vw;
    height: 64.667vw;
    img {
      width: 100%;
    }
  }
}
</style>
