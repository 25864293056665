<template>
  <div class="section">
    <div class="container">
      <el-row :gutter="24">
        <el-col :span="24">
          <div class="title">
            全面及时的
            <br />
            游戏折扣信息
          </div>
          <div class="tips">折扣提醒，不错过任何一个省钱机会</div>
        </el-col>
        <el-col :span="24">
          <div class="discount-container">
            <div class="discount-page">
              <img v-lazy="discountPage" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { Row, Col } from 'element-ui'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      discountPage:
        'http://switch-cdn.vgjump.com/img/mobile/offical-20200116-zj-01-discount.png',
    }
  },
}
</script>
<style scoped lang="scss">
.section {
  position: relative;
  height: 106.667vw !important;
  background: #e90000 !important;
}

.container {
  max-width: 92.5333vw;
  .title {
    text-align: center;
    padding: 0;
    margin: 0;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 6.4vw; //64px
    line-height: 8vw; //81px
    color: #ffffff;
  }
  .tips {
    text-align: center;
    margin-top: 2.667vw;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 4.2667vw;
    line-height: 5.333vw;
    text-align: center;
    color: #ff9c9c;
  }
}
.discount-container {
  position: relative;
  .discount-page {
    width: 42.6667vw;
    height: 64.26667vw;
    margin: auto;
    border-radius: 1.953vw;
    margin-top: 4vw;
  }
  .discount-item {
    width: 21.667vw;
    height: 7.708vw;
    position: absolute;
    top: -7.76vw;
    left: -2.0833vw;
    z-index: 2;
    border-radius: 1.9525vw;
  }
}
</style>
