<template>
  <div class="section">
    <svg
      viewBox="0 0 1920 650"
      fill="rgba(255,255,255,0)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0C0 0 584.914 17.5676 960 17.5676C1335.09 17.5676 1920 0 1920 0V650C1920 650 1335.09 632.432 960 632.432C584.914 632.432 0 650 0 650L0 0Z"
        fill="#E90000"
      />
    </svg>
    <div class="container">
      <el-row :gutter="24">
        <el-col :span="16">
          <div class="title">
            <p>全面及时的</p>
            <p>游戏折扣信息</p>
          </div>
          <div class="tips">
            <p>折扣提醒，不错过任何一个省钱机会</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="discount-container">
            <div class="discount-item">
              <img v-lazy="discountItem" />
            </div>
            <div class="discount-page">
              <img v-lazy="discountPage" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { Row, Col } from 'element-ui'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      discountPage:
        'http://switch-cdn.vgjump.com/img/discount/offical-20200116-zj-01-discount_page1.png',
      discountItem:
        'http://switch-cdn.vgjump.com/img/discount/offical-20200116-zj-01-discount_item2.png',
    }
  },
}
</script>
<style scoped lang="scss">
.section {
  position: relative;
  height: 33.854vw !important;
  //background: #E90000 !important;
  padding: 0;
}

.container {
  max-width: 51.042vw; //1480px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title p {
    padding: 0;
    margin: 0;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 2.5vw; //48px
    line-height: 3.125vw; //60px
    color: #ffffff;
  }
  .tips p {
    margin-top: 1.25rem;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6667vw;
    line-height: 2.0833vw;
    text-align: left;
    color: #ff9c9c;
  }
}
.discount-container {
  position: relative;
  .discount-page {
    background: rgba(0, 0, 0, 0);
    width: 17.552vw;
    //height: 35vw;
    position: absolute;
    top: -13.021vw;
    z-index: 1;
    border-radius: 1.953vw;
    box-shadow: 0px 2.593527317047119px 2.074821949005127px 0px
      rgba(0, 0, 0, 0.0197);
    box-shadow: 0px 6.232606887817383px 4.986085414886475px 0px
      rgba(0, 0, 0, 0.0283);
    box-shadow: 0px 11.735445976257324px 9.38835620880127px 0px
      rgba(0, 0, 0, 0.035);
    box-shadow: 0px 20.934030532836914px 16.747222900390625px 0px
      rgba(0, 0, 0, 0.1017);
    img {
      width: 100%;

      //box-shadow: 0px 39.15480041503906px 31.323841094970703px 0px rgba(0, 0, 0, 0.1003);
      //box-shadow: 0px 93.72197723388672px 74.97757720947266px 0px rgba(0, 0, 0, 0.07);
    }
  }
  .discount-item {
    width: 21.667vw;
    //height: 7.708vw;
    position: absolute;
    top: -7.76vw;
    left: -2.0833vw;
    z-index: 2;
    border-radius: 1.9525vw;
    box-shadow: 0px 2.494473457336426px 1.9955790042877197px 0px
      rgba(0, 0, 0, 0.0197);
    box-shadow: 0px 5.994566917419434px 4.795653343200684px 0px
      rgba(0, 0, 0, 0.0283);
    box-shadow: 0px 11.287237167358398px 9.029789924621582px 0px
      rgba(0, 0, 0, 0.035);
    box-shadow: 0px 20.134502410888672px 16.107603073120117px 0px
      rgba(0, 0, 0, 0.0417);
    box-shadow: 0px 37.65937423706055px 30.127498626708984px 0px
      rgba(0, 0, 0, 0.0503);
    box-shadow: 0px 90.1424789428711px 72.11398315429688px 0px
      rgba(0, 0, 0, 0.07);
    img {
      width: 100%;
    }
  }
}
</style>
