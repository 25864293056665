<template>
  <footer class="footer">
    <div class="container">
      <div class="content">
        <div class="company copyright-li">
          <img
            class="logo"
            src="http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-jump_logo_white.png"
          />
          <p>
            2021 © Jump |
            <a href="http://beian.miit.gov.cn" target="_black">
              京ICP备19027662号-2
            </a>
            | 北京矽岛科技有限公司 ｜ 京B2-20201300 ｜
            <a href="http://12377.cn" target="_black">网上有害信息举报</a>
            ｜举报邮箱：jubao@vgjump.com ｜客服电话：4006010456
          </p>
          <p></p>
        </div>
        <!-- <div class="link copyright-li">
          <ul>
            <p>
              未成年人守护热线：010-8963-3210 | 举报邮箱：jubao@vgjump.com</p>
            <p></p>
          </ul>
        </div>
        <div class="address copyright-li">
          <p><img class="gongan" src="http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-gongan.png" />京公网安备 11000002002046号 | 地址 : 北京市海淀区上地颐泉汇1号c座503</p>
          <p>地址 : 北京市海淀区上地颐泉汇1号c座503</p>
        </div> -->
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>
<style scoped lang="scss">
.copyright-li p {
  a {
    color: #ffffff;
  }
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 2.6667vw;
  line-height: 4.8vw;
  color: #ffffff;
}
.footer {
  width: 100%;
  background: #e90000;
  padding: 6vw 0;
  .container {
    max-width: 92vw;
    .content {
      margin-top: 2.25vw; //120px;
      .logo {
        width: 13.333vw;
        margin-bottom: 1.333vw;
      }
      .link {
        //margin-bottom: 2.5vw;//48px;
      }
      .address {
        .gongan {
          width: 3vw;
          display: inline;
          position: relative;
          top: -3px;
          margin-right: 5px;
        }
      }
    }
  }

  .fill-bg {
    background: transparent;
    width: 100%;
    height: 8.229vw; //158px;;
  }
}
</style>
