<template>
  <div class="section">
    <div class="container">
      <div class="post-container">
        <div class="content">
          <div class="title">海量游戏评测</div>
          <div class="title">真实评价一目了然</div>
          <div class="tips">
            百万活跃玩家讨论社区
            <br />
            听听真实玩家怎么说
          </div>
        </div>
        <div class="post">
          <img v-lazy="post" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      post:
        'http://switch-cdn.vgjump.com/img/mobile/offical-20200116-zj-01-post.png',
    }
  },
}
</script>
<style scoped lang="scss">
.section {
  padding: 8vw 0 0 0 !important;
  background: #e90000 !important;
}
.container {
  position: relative;
  max-width: 92.5333vw;
  .post-container {
    position: relative;
    .post {
      margin: auto;
      margin-top: 4vw;
      width: 38vw;
      height: 64vw;
    }
  }
  .content {
    width: 100%;
    text-align: center;
    .title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 6.4vw !important;
      line-height: 8.064vw !important;
      text-align: center;
      padding-top: 0;
      color: rgba(255, 255, 255, 1);
    }
    .tips {
      margin-top: 2.667vw;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 4.2667vw;
      line-height: 5.333vw;
      text-align: center;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
