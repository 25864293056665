<template>
  <div class="section">
    <div class="container">
      <div class="custom-title">
        和你一样
        <br />
        他们也选择Jump
      </div>
      <div class="partner">
        <img class="icon" v-lazy="partner" />
      </div>
    </div>
  </div>
</template>
<script>
import { Row, Col } from 'element-ui'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      partner:
        'http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-partner2.png',
    }
  },
}
</script>
<style scoped lang="scss">
.section {
  position: relative;
  background: #e90000 !important;
  padding: 8vw 0;
  .container {
    max-width: 92.5333vw;
    text-align: center;
    .custom-title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 6.4vw;
      line-height: 8vw;
      color: #ffffff;
    }
    .partner {
      margin-top: 5.3333vw;
      .icon {
        width: 100vw;
      }
    }
  }
}
</style>
