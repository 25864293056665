<template>
  <div>
    <about-index></about-index>
    <about-product />
    <about-media></about-media>
    <about-partner></about-partner>
    <about-us></about-us>
  </div>
</template>
<script>
import AboutIndex from './components/mobile/AboutIndex.vue'
import AboutProduct from './components/mobile/AboutProduct.vue'
import AboutMedia from './components/mobile/AboutMedia.vue'
import AboutPartner from './components/mobile/AboutPartner.vue'
import AboutUs from './components/mobile/AboutUs.vue'

export default {
  name: 'mAbout',
  bodyClass: 'index-page',
  components: {
    AboutIndex,
    AboutProduct,
    AboutMedia,
    AboutPartner,
    AboutUs,
  },
}
</script>
<style scope lang="scss">
.navbar {
  a {
    vertical-align: middle;
    &:not(.btn):not(.dropdown-item) {
      color: #020202;
    }
  }
}
</style>
