import Vue from 'vue'
import Router from 'vue-router'
import Index from './pages/Index.vue'
import About from './pages/About.vue'
import MIndex from './pages/MIndex.vue'
import MAbout from './pages/MAbout.vue'
import StarterNavbar from './layout/StarterNavbar.vue'
import StarterFooter from './layout/StarterFooter.vue'
import StarterFooterAbout from './layout/StarterFooterAbout.vue'
import MobileNavbar from './layout/MobileNavbar.vue'
import MobileFooter from './layout/MobileFooter.vue'
import MobileFooterAbout from './layout/MobileFooterAbout.vue'

Vue.use(Router)
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'index',
      components: {
        default: Index,
        header: StarterNavbar,
        footer: StarterFooter,
      },
      props: {
        header: { colorOnScroll: 10 },
        footer: { backgroundColor: '#E90000' },
      },
    },
    {
      path: '/about',
      name: 'about',
      components: {
        default: About,
        header: StarterNavbar,
        footer: StarterFooterAbout,
      },
      props: {
        header: { colorOnScroll: 10 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/m',
      name: 'mIndex',
      components: {
        default: MIndex,
        header: MobileNavbar,
        footer: MobileFooter,
      },
      props: {
        header: { colorOnScroll: 5 },
        footer: { backgroundColor: '#E90000' },
      },
    },
    {
      path: '/m/about',
      name: 'mAbout',
      components: {
        default: MAbout,
        header: MobileNavbar,
        footer: MobileFooterAbout,
      },
      props: {
        header: { colorOnScroll: 5 },
        footer: { backgroundColor: 'black' },
      },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
})
