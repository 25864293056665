<template>
  <footer class="footer">
    <div class="fill-bg"></div>
    <div class="container">
      <div class="content">
        <div class="company copyright-li">
          <img
            class="logo"
            src="http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-jump_logo.png"
          />
          <p>
            2021 © Jump |
            <a href="http://beian.miit.gov.cn" target="_black">
              京ICP备19027662号-2
            </a>
            | 北京矽岛科技有限公司 ｜ 京B2-20201300
            <br />
            <a href="http://12377.cn" target="_black">网上有害信息举报</a>
            ｜举报邮箱：jubao@vgjump.com ｜客服电话：4006010456
          </p>
          <p></p>
        </div>
        <!-- <div class="link copyright-li">
          <ul>
            <p>
              未成年人守护热线：010-8963-3210 | 举报邮箱：jubao@vgjump.com</p>
          </ul>
        </div>
        <div class="address copyright-li">
          <p><img class="gongan" src="http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-gongan.png" />京公网安备 11000002002046号 | 地址 : 北京市海淀区上地颐泉汇1号c座503</p>
          <p>地址 : 北京市海淀区上地颐泉汇1号c座503</p>
        </div> -->
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>
<style scoped lang="scss">
.copyright-li p {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375vw; //18px;
  line-height: 1.198vw; //21px;
  color: rgba(0, 0, 0, 0.4);
  a {
    color: rgba(0, 0, 0, 0.4);
  }
}
.footer {
  background: #ffffff;
  width: 100%;
  padding: 3.75vw 0;
  .container {
    max-width: 51.042vw; //1480px;
    .logo {
      width: 5.2083vw;
      margin-bottom: 1.146vw;
    }
    .content {
      margin-top: 6.25vw; //120px;
      .link {
        //margin-bottom: 2.5vw;//48px;
      }
      .address {
        .gongan {
          width: 1.771vw;
          display: inline;
          position: relative;
          top: -3px;
          margin-right: 10px;
        }
      }
    }
  }

  .fill-bg {
    width: 100%;
    height: 14.229vw; //158px;
    background: #e90000;
  }
}
</style>
