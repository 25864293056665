<template>
  <div class="section">
    <div class="background-position"></div>
    <div class="custom-card">
      <div class="container">
        <div class="post-container">
          <div class="post">
            <img v-lazy="post" />
          </div>
          <div class="post-comment">
            <img v-lazy="postComment" />
          </div>
        </div>
        <div class="content">
          <div class="title">海量游戏评测</div>
          <div class="title">真实评价一目了然</div>
          <div class="tips">
            百万活跃玩家讨论社区
            <br />
            听听真实玩家怎么说
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      post:
        'http://switch-cdn.vgjump.com/img/game/offical-20200116-zj-01-post1.png',
      postComment:
        'http://switch-cdn.vgjump.com/img/game/offical-20200116-zj-01-post_comment1.png',
    }
  },
}
</script>
<style scoped lang="scss">
.section {
  padding-bottom: 0;
  .background-position {
    background: #e90000;
    position: absolute;
    height: 39.156vw;
    width: 100%;
    bottom: -10.9375vw;
  }
  .custom-card {
    //background: #E90000;
    height: 42.656vw;
  }
  .container {
    height: 100%;
    position: relative;
    max-width: 51.042vw; //1480px;
    .post-container {
      position: relative;
      .post {
        position: absolute;
        width: 21.5625vw; //414px;
        //height: 40.625vw;//780px;
        top: 8.59375vw; //165px;
        left: 5.20833vw; //100px;
        border-radius: 1.876vw;
        box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px
          rgba(0, 0, 0, 0.0197);
        box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px
          rgba(0, 0, 0, 0.0283);
        box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px
          rgba(0, 0, 0, 0.035);
        box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px
          rgba(0, 0, 0, 0.0417);
        box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px
          rgba(0, 0, 0, 0.0503);
        box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07);
        img {
          width: 100%;
          //height: 100%;
        }
      }
      .post-comment {
        position: absolute;
        width: 21.5625vw; //414px;
        //height: 37.03125vw;//711px;
        left: 0.52vw; //10px;
        top: 18.75vw; //360px;
        border-radius: 1.875vw;
        box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px
          rgba(0, 0, 0, 0.0197);
        box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px
          rgba(0, 0, 0, 0.0283);
        box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px
          rgba(0, 0, 0, 0.035);
        box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px
          rgba(0, 0, 0, 0.0417);
        box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px
          rgba(0, 0, 0, 0.0503);
        box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.1);
        img {
          width: 100%;
          //height: 100%;
        }
      }
    }

    .right {
      position: relative;
    }
    .content {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -17%);
      float: right;
      text-align: right;
      .title {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vw; //48px
        line-height: 3.125vw; //60px
        text-align: right;
        padding-top: 0;
        color: #ffffff;
      }
      .tips {
        margin-top: 1.0833vw;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        font-size: 1.667vw; //32px
        line-height: 2.08333vw; // 40px
        text-align: right;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
</style>
