import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/scss/now-ui-kit.scss'
import '@/assets/demo/demo.css'
import '@/assets/jump/jump.scss'
import '@/assets/jump/mjump.scss'
import globalDirectives from './globalDirectives'
import globalMixins from './globalMixins'
import globalComponents from './globalComponents'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import VueLazyload from 'vue-lazyload'

locale.use(lang)

export default {
  install(Vue) {
    Vue.use(globalDirectives)
    Vue.use(globalMixins)
    Vue.use(globalComponents)
    Vue.use(VueLazyload, {
      observer: true,
      preLoad: 1.3,
      attempt: 2,
      // optional
      observerOptions: {
        rootMargin: '0px',
        threshold: 0.1,
      },
    })
  },
}
