<template>
  <div>
    <div class="page-header clear-filter" filter-color="jump">
      <div class="container">
        <basic-index></basic-index>
      </div>
    </div>
    <basic-discount></basic-discount>
    <basic-game></basic-game>
    <basic-post></basic-post>
  </div>
</template>
<script>
import BasicIndex from './components/mobile/BasicIndex.vue'
import BasicDiscount from './components/mobile/BasicDiscount.vue'
import BasicGame from './components/mobile/BasicGame.vue'
import BasicPost from './components/mobile/BasicPost.vue'

export default {
  name: 'mIndex',
  bodyClass: 'mindex-page',
  components: {
    BasicIndex,
    BasicDiscount,
    BasicGame,
    BasicPost,
  },
}
</script>
<style lang="scss"></style>
<style scope lang="scss">
.mindex-page {
  .page-header {
    min-height: 68.6667vw !important;
    max-height: 68.6667vw !important;
    height: 68.6667vw !important;
    .container {
      height: 50% !important;
    }
  }
}
.navbar {
  a {
    vertical-align: middle;
    &:not(.btn):not(.dropdown-item) {
      color: #020202;
    }
  }
}
</style>
