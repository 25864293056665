import Vue from 'vue'
import App from './App.vue'
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
//import router from './router';
import router from './starterRouter'
import NowUiKit from './plugins/now-ui-kit'
import scroll from 'vue-seamless-scroll'

Vue.config.productionTip = false

Vue.use(NowUiKit)
Vue.use(scroll)

new Vue({
  router,
  render: (h) => h(App),
  mounted() {
    // 这里的触发事件就是renderAfterDocumentEvent中的事件
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')
