<template>
  <div class="section">
    <svg viewBox="0 0 1920 803" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0C0 0 584.914 21.7027 960 21.7027C1335.09 21.7027 1920 0 1920 0V803C1920 803 1335.09 781.297 960 781.297C584.914 781.297 0 803 0 803L0 0Z"
        fill="#E90000"
      />
    </svg>
    <div class="container">
      <div class="custom-title">
        超过&nbsp;
        <span class="important">300万</span>
        玩家正在使用我们的产品
      </div>
      <div class="product-list">
        <el-row :gutter="24">
          <el-col :span="8">
            <div class="product-item">
              <img class="icon" v-lazy="jumpSwitch" />
              <div class="desc">Jump x Switch</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="product-item">
              <img class="icon" v-lazy="jumpPS" />
              <div class="desc">Jump x PS5</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="product-item">
              <img class="icon" v-lazy="jumpEpic" />
              <div class="desc">Jump x Epic</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { Row, Col } from 'element-ui'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      jumpSwitch:
        'http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-jump_QR.png',
      jumpPS:
        'http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-ps_QR.png',
      jumpEpic:
        'http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-epic_QR.png',
    }
  },
}
</script>
<style scoped lang="scss">
.section {
  position: relative;
  min-height: 41.823vw !important;
  //background: #E90000 !important;
  //padding: 6.25vw 0;
  .container {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 51.042vw; //1480px;
    text-align: center;
    .custom-title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 2.0833vw;
      line-height: 2.604vw;
      color: #ffffff;
      .important {
        font-size: 4.583vw;
        line-height: 5.775vw;
      }
    }
    .product-list {
      margin-top: 4.978vw;
      .product-item {
        .icon {
          width: 12.917vw;
          height: 12.917vw;
        }
        .desc {
          margin-top: 1.5625vw;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          font-size: 2.083vw;
          line-height: 2.604vw;
          /* or 50px */
          color: #ffffff;
        }
      }
    }
  }
}
</style>
