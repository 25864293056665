<template>
  <div class="section">
    <div class="container">
      <div class="custom-title">
        超过&nbsp;
        <span class="important">300万</span>
        玩家
        <br />
        正在使用我们的产品
      </div>
      <div class="product-list">
        <el-row :gutter="24">
          <el-col :span="8">
            <div class="product-item">
              <div class="icon-container">
                <img class="icon" v-lazy="jumpSwitch" />
              </div>
              <div class="desc">Jump x Switch</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="product-item">
              <div class="icon-container">
                <img class="icon" v-lazy="jumpPs" />
              </div>
              <div class="desc">Jump x PS5</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="product-item">
              <div class="icon-container">
                <img class="icon" v-lazy="jumpEpic" />
              </div>
              <div class="desc">Jump x Epic</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { Row, Col } from 'element-ui'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      jumpSwitch:
        'http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-jump_QR.png',
      jumpPs:
        'http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-ps_QR.png',
      jumpEpic:
        'http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-epic_QR.png',
    }
  },
}
</script>
<style scoped lang="scss">
.section {
  position: relative;
  background: #e90000 !important;
  padding: 8vw 0;
  .container {
    max-width: 92.5333vw;
    text-align: center;
    .custom-title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 6.4vw;
      line-height: 8vw;
      color: #ffffff;
      .important {
        font-size: 8.5333vw;
        line-height: 10.752vw;
      }
    }
    .product-list {
      margin-top: 5.333vw;
      .product-item {
        .icon-container {
          margin: auto;
          width: 19.2vw;
          height: 19.2vw;
          border-radius: 6.667vw;
          // box-shadow: 0px 1.9994187355041504px 1.5995352268218994px 0px rgba(0, 0, 0, 0.0197);
          // box-shadow: 0px 4.804881572723389px 3.843905210494995px 0px rgba(0, 0, 0, 0.0283);
          // box-shadow: 0px 9.047165870666504px 7.237732887268066px 0px rgba(0, 0, 0, 0.035);
          // box-shadow: 0px 16.13859748840332px 12.91087818145752px 0px rgba(0, 0, 0, 0.1417);
          //box-shadow: 0px 30.18547248840332px 24.14837646484375px 0px rgba(0, 0, 0, 0.1503);
          //box-shadow: 0px 72.25274658203125px 57.80220031738281px 0px rgba(0, 0, 0, 0.07);
          .icon {
            width: 100%;
          }
        }

        .desc {
          margin-top: 4vw;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          font-size: 3.2vw;
          line-height: 4vw;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
