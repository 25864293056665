<template>
  <div class="section">
    <div class="container">
      <el-row :gutter="24">
        <el-col :span="12">
          <div class="about-us">
            <div class="about-us-item">
              <div class="title">商务合作</div>
              <div class="content">
                邮箱: business@vgjump.com
                <br />
                微信: PunkReed（张先生）
              </div>
            </div>
            <div class="about-us-item join-us">
              <div class="title">游戏发行</div>
              <div class="content">
                邮箱：publish@cyberisle-inc.com
                <br />
                微信：PunkReed（张先生）
              </div>
            </div>
            <div class="about-us-item join-us">
              <div class="title">加入我们</div>
              <div class="content">
                地址：北京市海淀区上地东路颐泉汇
                <br />
                邮箱：Job@vgjump.com
              </div>
            </div>
          </div>
          <el-button class="join" type="danger" round @click="toBoss">
            投递简历
          </el-button>
        </el-col>
        <el-col :span="12">
          <div class="us-map">
            <img class="icon" v-lazy="mapUrl" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { Row, Col, Button } from 'element-ui'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
  },
  data() {
    return {
      mapUrl:
        'http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-address.png',
    }
  },
  methods: {
    toBoss() {
      window.open('https://mp.weixin.qq.com/s/mASkcpwmgcxNkOjj12CPvw')
    },
  },
}
</script>
<style scoped lang="scss">
:focus {
  outline: 0;
}
.section {
  position: relative;
  .container {
    text-align: left;
    max-width: 51.042vw; //1480px;
    .about-us {
      .about-us-item {
        .title {
          padding: 0;
          font-family: PingFang SC;
          font-style: normal;
          font-size: 2.0833vw;
          line-height: 3.125vw;
          color: #000000;
        }
        .content {
          margin-top: 1.5625vw;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 1.458vw;
          line-height: 2.083vw;
          color: #000000;
        }
      }
      .join-us {
        margin-top: 3.004vw;
      }
    }
    .us-map {
      width: 28.489vw;
      height: 28.489vw;
    }
    .join {
      margin-top: 5.125vw; //90px
      background-color: #e90000;
      border-color: #e90000;
      border-radius: 5.625vw;
      //min-width: 10vw;
      width: 12.5vw;
      height: 5.104vw;
      //min-height: 5vw;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 2.0833vw;
      line-height: 3.125vw;
      color: #ffffff;
    }
  }
}
</style>
