<template>
  <navbar
    class="navbar"
    position="fixed"
    type="white"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto custom-navbar"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/m">
        <img
          class="logo"
          width="100"
          height="28"
          src="http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-jump_logo.png?v=1"
        />
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item" @click="clickIndex">
        <a class="nav-link" rel="tooltip" data-placement="bottom">
          <router-link v-popover:popover1 class="navbar-brand" :to="indexPage">
            首页
          </router-link>
        </a>
      </li>
      <li class="nav-item" @click="clickAbout">
        <a class="nav-link" rel="tooltip" data-placement="bottom">
          <router-link v-popover:popover1 class="navbar-brand" :to="aboutPage">
            关于我们
          </router-link>
        </a>
      </li>
      <li class="nav-item" @click="clickAnyItem">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Wechat"
          data-placement="bottom"
          href="https://mp.weixin.qq.com/s/AjKYHkmDWQXWbNnX8UrRTw"
          target="_black"
        >
          <img
            class="icon"
            src="http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-winxin.png"
          />
        </a>
      </li>
      <li class="nav-item" @click="clickAnyItem">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on BiLiBiLi"
          data-placement="bottom"
          href="https://space.bilibili.com/321899908"
          target="_blank"
        >
          <img
            class="icon"
            src="http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-bilibili.png"
          />
        </a>
      </li>
      <li class="nav-item" @click="clickAnyItem">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on ZhiHu"
          data-placement="bottom"
          href="https://www.zhihu.com/people/vgjump"
          target="_blank"
        >
          <img
            class="icon"
            src="http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-zhihu.png"
          />
        </a>
      </li>
      <li class="nav-item" @click="clickAnyItem">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on WeiBo"
          data-placement="bottom"
          href="https://www.zhihu.com/people/vgjump"
          target="_blank"
        >
          <img
            class="icon"
            src="http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-weibo.png"
          />
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components'
import { Popover } from 'element-ui'

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  computed: {
    indexPage() {
      return this._isMobile() ? '/m' : '/'
    },
    aboutPage() {
      return this._isMobile() ? '/m/about' : '/about'
    },
  },
  components: {
    Navbar,
    [Popover.name]: Popover,
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    clickIndex() {
      this.$router.replace(this.indexPage)
      this.clickAnyItem()
    },
    clickAbout() {
      this.$router.replace(this.aboutPage)
      this.clickAnyItem()
    },
    clickAnyItem() {
      document.getElementsByClassName('navbar-toggler')[0].click()
    },
  },
}
</script>

<style scoped lang="scss">
.logo {
  width: 13.333vw;
  height: 3.7333vw;
}
.nav-item {
  .nav-link {
    .icon {
      width: 32px;
    }
  }
}
::v-deep .navbar {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep {
  .sidebar-collapse .navbar-collapse:before {
    background: linear-gradient(#e90000 100%, #e90000 100%);
    opacity: 0.76;
  }
}
</style>
