<template>
  <div class="section">
    <img
      class="back"
      src="http://switch-cdn.vgjump.com/img/mobile/offical-20200116-zj-01-back.png?v=1"
    />
    <div class="container">
      <div class="slogan">
        专为全平台玩家打造的
        <br />
        全能游戏平台
      </div>
      <div class="product" @mouseleave="productMouseLeave">
        <template>
          <el-popover
            ref="popover1"
            popper-class="popover"
            placement="bottom"
            trigger="click"
          >
            <img
              class="qrcode"
              src="http://switch-cdn.vgjump.com/img/qrcode/offical-20200116-zj-01-switch.jpeg?imageView2/0/format/jpg/q/75|imageslim"
            />
          </el-popover>
          <div
            class="product-item"
            name="jump"
            @mouseenter="productItemMouseEnter"
            @mouseleave="productItemMouseLeave"
          >
            <img v-popover:popover1 class="icon" :src="jumpUrl" />
          </div>
        </template>
        <template>
          <el-popover
            ref="popover2"
            popper-class="popover"
            placement="bottom"
            trigger="click"
          >
            <img
              class="qrcode"
              src="http://switch-cdn.vgjump.com/img/qrcode/offical-20200116-zj-01-switch.jpeg?imageView2/0/format/jpg/q/75|imageslim"
            />
          </el-popover>
          <div
            class="product-item"
            name="switch"
            @mouseenter="productItemMouseEnter"
            @mouseleave="productItemMouseLeave"
          >
            <img v-popover:popover2 class="icon" :src="switchUrl" />
          </div>
        </template>
        <template>
          <el-popover
            ref="popover3"
            popper-class="popover"
            placement="bottom"
            trigger="click"
          >
            <img
              class="qrcode"
              src="http://switch-cdn.vgjump.com/img/qrcode/offical-20200116-zj-01-ps.jpeg?imageView2/0/format/jpg/q/75|imageslim"
            />
          </el-popover>
          <div
            class="product-item"
            name="playstation"
            @mouseenter="productItemMouseEnter"
            @mouseleave="productItemMouseLeave"
          >
            <img v-popover:popover3 class="icon" :src="playstationUrl" />
          </div>
        </template>
        <template>
          <el-popover
            ref="popover4"
            popper-class="popover"
            placement="bottom"
            trigger="click"
          >
            <img
              class="qrcode"
              src="http://switch-cdn.vgjump.com/img/qrcode/offical-20200116-zj-01-steam.jpeg?imageView2/0/format/jpg/q/75|imageslim"
            />
          </el-popover>
          <div
            class="product-item"
            name="steam"
            @mouseenter="productItemMouseEnter"
            @mouseleave="productItemMouseLeave"
          >
            <img v-popover:popover4 class="icon" :src="steamUrl" />
          </div>
        </template>
        <template>
          <el-popover
            ref="popover5"
            popper-class="popover"
            placement="bottom"
            trigger="click"
          >
            <img
              class="qrcode"
              src="http://switch-cdn.vgjump.com/img/qrcode/offical-20200116-zj-01-xbox.jpeg?imageView2/0/format/jpg/q/75|imageslim"
            />
          </el-popover>
          <div
            class="product-item"
            name="xbox"
            @mouseenter="productItemMouseEnter"
            @mouseleave="productItemMouseLeave"
          >
            <img v-popover:popover5 class="icon" :src="xboxUrl" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { Popover } from 'element-ui'

export default {
  components: {
    [Popover.name]: Popover,
  },
  data() {
    return {
      selected: 'jump',
      isActive: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  computed: {
    steamUrl() {
      if (this.selected == 'steam') {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-steam.png'
      } else {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-steam-grey.png'
      }
    },
    jumpUrl() {
      if (this.selected == 'jump') {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-jump.png'
      } else {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-jump-grey.png'
      }
    },
    switchUrl() {
      if (this.selected == 'switch') {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-switch.png'
      } else {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-switch-grey.png'
      }
    },
    playstationUrl() {
      if (this.selected == 'playstation') {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-playstation.png'
      } else {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-playstation-grey.png'
      }
    },
    xboxUrl() {
      if (this.selected == 'xbox') {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-xbox.png'
      } else {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-xbox-grey.png'
      }
    },
  },
  methods: {
    productItemMouseEnter(e) {
      const a = e.target.getAttribute('name')
      this.selected = a
      this.isActive = true
    },
    productItemMouseLeave() {
      // const a = e.target.getAttribute("name");
      // console.log(a)
      //this.selected = ''
    },
    productMouseLeave() {
      //this.selected = 'jump'
    },
    handleScroll() {
      const odiv = document.getElementsByName(this.selected)
      if (odiv.length > 0 && this.isActive) {
        odiv[0].click()
        this.isActive = false
      }
    },
  },
}
</script>
<style lang="scss"></style>
<style scoped lang="scss">
:focus {
  outline: 0;
}
.section {
  padding-top: 60vw !important;
  background: rgba(0, 0, 0, 0) !important;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .back {
    width: 100%;
    position: absolute;
    left: 0;
    top: 34.333vw;
  }
}
.index-page .page-header {
  height: 68.6667vw;
}
.slogan {
  color: #000000 !important;
  font-family: PingFang SC !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 6.4vw !important;
  line-height: 8.064vw !important;
  text-align: center !important;
}
.product {
  margin-top: 2.65vw;
  .qrcode {
    width: 6.5625vw;
  }
  .product-item {
    display: inline;
    margin-right: 4.2666vw;
    .icon {
      width: 7.4667vw;
    }
  }
}
</style>
