<template>
  <div class="section">
    <div class="container">
      <div class="custom-title">为玩家创造价值</div>
      <div class="custom-content">
        很久很久以前，玩家之间的交流是一种单纯快乐、互助友爱的事情，然而随着时空的变化，国内的游戏社区和游戏环境一样，变得乌烟瘴气，鸡飞狗跳，到处充斥着谩骂、举报和抄袭。
        <br />
        <br />
        面对这样的困境，一群热爱游戏的玩家走到了一起，他们想要做一件改变玩家游戏体验的事情，Jump的故事就此拉开了序幕...
      </div>
    </div>
    <div class="container tab-container">
      <el-tabs class="custom-tab" v-model="tabSelected">
        <el-tab-pane name="egg">
          <span class="label-item" slot="label">
            <img class="icon" :src="eggUrl" />
            <div class="title">诞生之地</div>
          </span>
          <div class="tab-content">
            在游戏中，Jump（跳跃）是最简单却又最重要的动作，是无数玩家少年时期坐在电视面前手握手柄的美好记忆，更是玩家和游戏主角一起披荆斩棘，与
            BOSS 斗智斗勇的元气之魂。
            <br />
            2018 年 3 月 12
            日，几位热爱游戏的人怀揣「为玩家创造价值」的初心走到一起，为了引领电子游戏文化，改变国内游戏环境，让大家接触更多出众的游戏，创立了
            Jump——用有温度的科技，为玩家带来一个快乐的「家」。
          </div>
        </el-tab-pane>
        <el-tab-pane name="maoxian">
          <span class="label-item" slot="label">
            <img class="icon" :src="maoxianUrl" />
            <div class="title">冒险历程</div>
          </span>
          <div class="tab-content">
            从2018年初至今，Jump从几个人维护的Switch比价小工具，历经行业争斗、团队变革、生存危机等一系列磨砺，发展成为数十人的团队，覆盖
            <br />
            Switch、PlayStation、Xbox、Steam等主机及PC游戏的综合游戏平台。产品形态包含App、小程序、公众号、商城等，成为深受玩家和行业认可的优质品牌。
          </div>
        </el-tab-pane>
        <el-tab-pane name="partner">
          <span class="label-item" slot="label">
            <img class="icon" :src="partnerUrl" />
            <div class="title">同行伙伴</div>
          </span>
          <div class="tab-content">
            Jump的核心团队来自互联网一线公司,拥有领先业界的研发、设计、运营经验，并且每一位都是资深游戏爱好者。Jump已与索尼、微软、腾讯任天堂、育碧等行业头部公司进行过多次深度合作。
            <br />
            在Jump最重要的不仅是员工和合作伙伴，更是超过300万，并且在飞速增长中的玩家用户。正是因为有了玩家每一次使用、浏览和购买，成就了今天的Jump。
          </div>
        </el-tab-pane>
        <el-tab-pane name="important">
          <span class="label-item" slot="label">
            <img class="icon" :src="importantUrl" />
            <div class="title">重要之物</div>
          </span>
          <div style="text-align: center" class="tab-content">
            使命：为玩家创造价值
            <br />
            愿景：成为中国最好的游戏玩家平台
            <br />
            价值观：玩家第一 追求卓越 勇于突破 合作共赢
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane } from 'element-ui'
export default {
  components: {
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
  computed: {
    eggUrl() {
      if (this.tabSelected == 'egg') {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-egg-selected.png'
      } else {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-egg.png'
      }
    },
    maoxianUrl() {
      if (this.tabSelected == 'maoxian') {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-maoxian-selected.png'
      } else {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-maoxian.png'
      }
    },
    partnerUrl() {
      if (this.tabSelected == 'partner') {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-partner-selected.png'
      } else {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-partner.png'
      }
    },
    importantUrl() {
      if (this.tabSelected == 'important') {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-important-selected.png'
      } else {
        return 'http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-important.png'
      }
    },
  },
  data() {
    return {
      tabSelected: 'egg',
    }
  },
}
</script>
<style lang="scss">
:focus {
  outline: 0;
}
</style>
<style scoped lang="scss">
:focus {
  outline: 0;
}
.section {
  padding: 28vw 0 8vw 0;
  .container {
    max-width: 92.5333vw;
    .custom-title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 6.25vw;
      line-height: 7.864vw;
      text-align: left;
      color: rgba(0, 0, 0, 0.8);
    }
    .custom-content {
      width: 100%;
      margin-top: 2.6667vw;
      font-family: PingFang SC;
      font-size: 4.26667vw;
      line-height: 5.9733vw;
      text-align: left;
      color: rgba(0, 0, 0, 0.4);
    }

    .custom-tab {
      max-width: 92.5333vw;
      margin: auto;
      .tab-content {
        margin-top: 1.333vw;
        width: 100%;
        min-height: 66vw;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 4.26667vw;
        line-height: 5.9733vw;
        text-align: left;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.4);
      }
      & ::v-deep .el-tabs__nav-wrap::after {
        display: none !important;
      }
      & ::v-deep .el-tabs__item {
        padding: 0 0.733vw !important;
        .label-item {
          .icon {
            width: 20vw;
            height: 13.3333vw;
          }
          .title {
            position: relative;
            bottom: 0;
            text-align: center;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: 4.2667vw;
            line-height: 5.3333vw;
            color: rgba(0, 0, 0, 0.9);
          }
        }
      }
      & ::v-deep .el-tabs__active-bar {
        width: 11.667vw;
        height: 2.5625vw;
        background: rgba(233, 0, 0, 0.5);
        border-radius: 1.198vw; //23px;
        z-index: -1;
        bottom: 4px;
      }
    }
  }
  .tab-container {
    margin-top: 8vw;
  }
}
</style>
