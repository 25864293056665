<template>
  <!-- <div class="mask"></div> -->
  <div id="div1" class="back-img">
    <!-- <ul
      class="img-container"
      v-for="(group, index) in backgroundList"
      :key="index"
    >
      <li class="img-item" v-for="(url, ind) in group" :key="ind">
        <img v-if="ind < 7" :src="url" />
      </li>
    </ul> -->

    <ul
      class="img-container"
      v-for="(group, index) in backgroundList"
      :key="index"
    >
      <vue-seamless-scroll
        :data="group"
        class="warp"
        :class-option="{
          ...classOption,
          direction: index % 2 == 1 ? 3 : 2,
        }"
      >
        <li class="img-item" v-for="(url, ind) in group" :key="ind">
          <img v-if="ind < 7" :src="url" :key="url" />
        </li>
      </vue-seamless-scroll>
    </ul>
  </div>
</template>
<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      classOption: {
        limitMoveNum: 1,
        direction: 3,
        step: 0.5,
      },
      backgroundList: [
        ['http://switch-cdn.vgjump.com/img/group/offical-20200116-zj-02-5.jpg'],
        ['http://switch-cdn.vgjump.com/img/group/offical-20200116-zj-02-1.jpg'],
        ['http://switch-cdn.vgjump.com/img/group/offical-20200116-zj-02-2.jpg'],
        ['http://switch-cdn.vgjump.com/img/group/offical-20200116-zj-02-3.jpg'],
        ['http://switch-cdn.vgjump.com/img/group/offical-20200116-zj-02-4.jpg'],
      ],
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style scope lang="scss">
.mask {
  position: absolute;
  top: 0;
  height: 50vh;
  width: 100%;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
}
.back-img {
  position: absolute;
  top: -4.083vw;
  z-index: -1;
  overflow: hidden;
  min-height: 10vw;
}
.warp {
  width: 500vw;
}
.img-container {
  overflow: hidden;
  margin: auto !important;
  width: 500vw;
  .img-item {
    max-height: 13vw;
    img {
      width: 100vw;
      border-radius: 8px;
    }
    margin-top: 0.833vw;
  }
  &:nth-child(odd) {
    .img-item {
      //position: relative;
      //left: 3.25vw;
    }
    position: relative;
    margin-left: 0.833vw;

    //left: 3.25vw;
  }
  &:nth-child(even) {
    .img-item {
      //
      //left: -3.125vw
    }
    position: relative;
    //left: -3.125vw
  }
}
</style>
<style scoped lang="scss">
.mask {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
}
.back-img {
  position: absolute;
  top: -8.083vw !important;
  z-index: -1;
  top: 0;
  border: 1px solid gray;
  width: 200vw;
  height: 100vh;
  overflow: hidden;
  .img-container {
    margin: auto !important;
    li {
      list-style-type: none;
    }
    .img-item {
      img {
        width: 100vw;
        border-radius: 8px;
      }
      margin-top: 0.833vw;
    }
    &:nth-child(odd) {
      .img-item {
        float: left;
        margin-right: 0.833vw;
      }
      //width: 200vw;
    }
    &:nth-child(even) {
      .img-item {
        float: left;
        margin-left: 0.833vw;
      }
      //width: 200vw;
    }
  }
}

@keyframes rolling {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}
</style>
