<template>
  <div class="section">
    <div class="container">
      <div class="container main-container">
        <div class="content">
          <div class="title">全平台游戏库&nbsp;游戏信息全掌握</div>
          <div class="tip">游戏价格、攻略、奖杯，看Jump就够了</div>
        </div>
        <div class="product">
          <div class="history">
            <img v-lazy="history" />
          </div>
          <div class="gonglue">
            <img v-lazy="gonglue" />
          </div>
          <div class="rank">
            <img v-lazy="rank" />
          </div>
          <div class="saving">
            <img v-lazy="saving" />
          </div>
          <div class="trophy">
            <img v-lazy="trophy" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      history:
        'http://switch-cdn.vgjump.com/img/game/offical-20200116-zj-01-history.png',
      gonglue:
        'http://switch-cdn.vgjump.com/img/game/offical-20200116-zj-01-gonglue.png',
      rank:
        'http://switch-cdn.vgjump.com/img/game/offical-20200116-zj-01-rank.png',
      saving:
        'http://switch-cdn.vgjump.com/img/game/offical-20200116-zj-01-saving.png',
      trophy:
        'http://switch-cdn.vgjump.com/img/game/offical-20200116-zj-01-trophy.png',
    }
  },
}
</script>
<style scoped lang="scss">
.section {
  padding: 17.604vw 0;
}
.container {
  max-width: 51.042vw; //1480px;
}
.main-container {
  margin: auto;
  width: 51.146vw;
  height: 26.354vw;
  background-color: #f5f5f5;
  border-radius: 2.08333vw; //40px
  position: relative;
  box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px
    rgba(0, 0, 0, 0.0197);
  box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px
    rgba(0, 0, 0, 0.0283);
  box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px
    rgba(0, 0, 0, 0.035);
  box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px
    rgba(0, 0, 0, 0.0417);
  box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px
    rgba(0, 0, 0, 0.2);
  box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.15);

  .content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 2.5vw; //48px
      line-height: 3.125vw; //60px
      text-align: center;
      color: rgba(0, 0, 0, 0.8);
    }
    .tip {
      margin-top: 1.0833vw;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 1.667vw; //32px
      line-height: 2.08333vw; // 40px
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
.product {
  .history {
    width: 20.8333vw; //400px;
    height: 14.58333vw; //280px;
    position: absolute;
    top: 5.56875vw;
    right: -12.635vw;
    border-radius: 8.123vw;
    box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px
      rgba(0, 0, 0, 0.0197);
    box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px
      rgba(0, 0, 0, 0.0283);
    box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px
      rgba(0, 0, 0, 0.035);
    box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px
      rgba(0, 0, 0, 0.0417);
    box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px
      rgba(0, 0, 0, 0.0503);
    box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07);
    img {
      width: 100%;
    }
  }
  .rank {
    width: 18.95833vw; //364px;
    height: 18.95833vw;
    position: absolute;
    top: -10.71875vw;
    left: -4.3333vw;
    border-radius: 8.123vw;
    box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px
      rgba(0, 0, 0, 0.0197);
    box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px
      rgba(0, 0, 0, 0.0283);
    box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px
      rgba(0, 0, 0, 0.035);
    box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px
      rgba(0, 0, 0, 0.0417);
    box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px
      rgba(0, 0, 0, 0.0503);
    box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07);
    img {
      width: 100%;
    }
  }
  .saving {
    width: 23.333vw; //448px;
    height: 8.3333vw; //160px;
    top: -5.052vw; //-97px;
    right: -1.271vw; //110px;
    position: absolute;
    border-radius: 8.123vw;
    box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px
      rgba(0, 0, 0, 0.0197);
    box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px
      rgba(0, 0, 0, 0.0283);
    box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px
      rgba(0, 0, 0, 0.035);
    box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px
      rgba(0, 0, 0, 0.0417);
    box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px
      rgba(0, 0, 0, 0.0503);
    box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07);
    img {
      width: 100%;
    }
  }
  .trophy {
    width: 23.333vw; //448px;
    height: 8.3333vw; //160px;
    position: absolute;
    bottom: -5vw; //-96px;
    left: -2.604vw; //-50px;
    border-radius: 8.123vw;
    box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px
      rgba(0, 0, 0, 0.0197);
    box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px
      rgba(0, 0, 0, 0.0283);
    box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px
      rgba(0, 0, 0, 0.035);
    box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px
      rgba(0, 0, 0, 0.0417);
    box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px
      rgba(0, 0, 0, 0.0503);
    box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07);
    img {
      width: 100%;
    }
  }
  .gonglue {
    width: 20.8333vw; //400px;
    height: 16.6667vw; //320px;
    position: absolute;
    bottom: -12.604vw; //-242px;
    right: 1.917vw;
    border-radius: 8.123vw;
    box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px
      rgba(0, 0, 0, 0.0197);
    box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px
      rgba(0, 0, 0, 0.0283);
    box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px
      rgba(0, 0, 0, 0.035);
    box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px
      rgba(0, 0, 0, 0.0417);
    box-shadow: 0px 41.777610778808594px 33.422088623046875px 0px
      rgba(0, 0, 0, 0.0703);
    //box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.00);
    img {
      width: 100%;
    }
  }
}
</style>
