<template>
  <div class="section">
    <div class="container">
      <div class="custom-title">
        极具行业影响力的
        <br />
        游戏媒体矩阵
      </div>
      <div class="product-list">
        <el-row :gutter="24">
          <el-col style="padding: 0" :span="8">
            <div class="product-item">
              <div class="title">Top3</div>
              <div class="desc">
                全网游戏媒体
                <br />
                综合影响力
              </div>
            </div>
          </el-col>
          <el-col style="padding: 0" :span="8">
            <div class="product-item">
              <div class="title">千万级</div>
              <div class="desc">
                月均有效触达
                <br />
                玩家数
              </div>
            </div>
          </el-col>
          <el-col style="padding: 0" :span="8">
            <div class="product-item">
              <div class="title">98%</div>
              <div class="desc">用户综合满意度</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="media-list">
        <el-row :gutter="24">
          <el-col style="padding: 0" :span="4">
            <div class="media-item">
              <a
                href="https://mp.weixin.qq.com/s/AjKYHkmDWQXWbNnX8UrRTw"
                target="_black"
              >
                <img
                  class="icon"
                  src="http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-weixin.png"
                />
              </a>
            </div>
          </el-col>
          <el-col style="padding: 0" :span="4">
            <div class="media-item">
              <a href="https://space.bilibili.com/321899908" target="_black">
                <img
                  class="icon"
                  src="http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-bilibili.png"
                />
              </a>
            </div>
          </el-col>
          <el-col style="padding: 0" :span="4">
            <div class="media-item">
              <a href="https://www.zhihu.com/people/vgjump" target="_black">
                <img
                  class="icon"
                  src="http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-zhihu.png"
                />
              </a>
            </div>
          </el-col>
          <el-col style="padding: 0" :span="4">
            <div class="media-item">
              <a href="https://weibo.com/6510377109/profile" target="_black">
                <img
                  class="icon"
                  src="http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-weibo.png"
                />
              </a>
            </div>
          </el-col>
          <el-col style="padding: 0" :span="4">
            <div class="media-item">
              <a
                href="https://www.xiaohongshu.com/user/profile/5fc5b56c000000000100698f"
                target="_black"
              >
                <img
                  class="icon"
                  src="http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-xiaohongshu.png"
                />
              </a>
            </div>
          </el-col>
          <el-col style="padding: 0" :span="4">
            <div class="media-item">
              <a
                href="https://www.youtube.com/channel/UCPpCPQDpiSYc2VYQVvPEtnA"
                target="_black"
              >
                <img
                  class="icon"
                  src="http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-youtube.png"
                />
              </a>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="tips">
        <el-row :gutter="24">
          <el-col class="text" :span="24">*基于第三方行业报告综合计算</el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { Row, Col } from 'element-ui'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
}
</script>
<style scoped lang="scss">
.section {
  position: relative;
  padding: 8vw 0;
  .container {
    max-width: 92.5333vw;
    text-align: center;
    .custom-title {
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 6.4vw;
      line-height: 8vw;
      color: rgba(0, 0, 0, 0.8);
    }
    .product-list {
      margin-top: 5.3333vw;
      &:nth-child(3) .title {
        font-family: Axiforma;
      }
      & ::v-deep .el-col {
        padding: 0;
      }
      .product-item {
        .title {
          padding: 0;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: bold;
          font-size: 6.4vw;
          line-height: 8vw;
          text-align: center;
          color: #e90000;
        }

        .desc {
          margin-top: 1.3333vw;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          font-size: 4.266vw;
          line-height: 5.333vw;
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }
    .media-list {
      margin-top: 5.3333vw;
      & ::v-deep .el-col {
        padding: 0;
      }
      .media-item {
        .icon {
          width: 12.8vw;
        }
      }
    }
    .tips {
      text-align: right;
      margin-top: 5.3333vw;
      .text {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 3.2vw;
        line-height: 4vw;
        text-align: center;
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
