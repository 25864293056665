<template>
  <div class="section">
    <svg viewBox="0 0 1920 1023" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0C0 0 584.914 27.6486 960 27.6486C1335.09 27.6486 1920 0 1920 0V1023C1920 1023 1335.09 995.351 960 995.351C584.914 995.351 0 1023 0 1023L0 0Z"
        fill="#E90000"
      />
    </svg>

    <div class="container">
      <div class="custom-title">和你一样，他们也选择Jump</div>
      <div class="partner">
        <img class="icon" v-lazy="partner" />
      </div>
    </div>
  </div>
</template>
<script>
import { Row, Col } from 'element-ui'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      partner:
        'http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-partner2.png',
    }
  },
}
</script>
<style scoped lang="scss">
.section {
  position: relative;
  //background: #E90000 !important;
  //padding: 6.25vw 0;
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 64.0625vw; //1480px;
    text-align: center;
    .custom-title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 3.333vw;
      line-height: 4.27vw;
      color: #ffffff;
      .important {
        font-size: 9.302vw;
        line-height: 7.875vw;
      }
    }
    .partner {
      margin-top: 6.25vw;
      .icon {
        width: 77.083vw;
      }
    }
  }
}
</style>
