<template>
  <div class="section">
    <div class="container">
      <div class="slogan">
        <p>专为全平台玩家打造的</p>
        <p>全能游戏平台</p>
      </div>
      <div class="product" @mouseleave="productMouseLeave">
        <template>
          <el-popover
            ref="popover1"
            popper-class="popover"
            placement="bottom"
            trigger="hover"
          >
            <img class="qrcode" v-lazy="jumpQRUrl" />
            <!-- <div class="tips" style="
            position: absolute;bottom: -20px;left: 30px;color:rgba(0,0,0,0.3);font-size:12px;line-height:15px
            ">打开微信扫一扫</div> -->
          </el-popover>
          <div
            class="product-item"
            name="jump"
            @mouseenter="productItemMouseEnter"
            @mouseleave="productItemMouseLeave"
          >
            <img v-popover:popover1 class="icon" v-lazy="jumpUrl" />
          </div>
        </template>
        <template>
          <el-popover
            ref="popover2"
            popper-class="popover"
            placement="bottom"
            trigger="hover"
          >
            <img class="qrcode" v-lazy="switchQRUrl" />
            <!-- <div class="tips" style="
            position: absolute;bottom: -20px;left: 30px;color:rgba(0,0,0,0.3);font-size:12px;line-height:15px
            ">打开微信扫一扫</div> -->
          </el-popover>
          <div
            class="product-item"
            name="switch"
            @mouseenter="productItemMouseEnter"
            @mouseleave="productItemMouseLeave"
          >
            <img v-popover:popover2 class="icon" v-lazy="switchUrl" />
          </div>
        </template>
        <template>
          <el-popover
            ref="popover3"
            popper-class="popover"
            placement="bottom"
            trigger="hover"
          >
            <img class="qrcode" v-lazy="psQRUrl" />
            <!-- <div class="tips" style="
            position: absolute;bottom: -20px;left: 30px;color:rgba(0,0,0,0.3);font-size:12px;line-height:15px
            ">打开微信扫一扫</div> -->
          </el-popover>
          <div
            class="product-item"
            name="playstation"
            @mouseenter="productItemMouseEnter"
            @mouseleave="productItemMouseLeave"
          >
            <img v-popover:popover3 class="icon" v-lazy="playstationUrl" />
          </div>
        </template>
        <template>
          <el-popover
            ref="popover4"
            popper-class="popover"
            placement="bottom"
            trigger="hover"
          >
            <img class="qrcode" v-lazy="steamQRUrl" />
            <!-- <div class="tips" style="
            position: absolute;bottom: -20px;left: 30px;color:rgba(0,0,0,0.3);font-size:12px;line-height:15px
            ">打开微信扫一扫</div> -->
          </el-popover>
          <div
            class="product-item"
            name="steam"
            @mouseenter="productItemMouseEnter"
            @mouseleave="productItemMouseLeave"
          >
            <img v-popover:popover4 class="icon" v-lazy="steamUrl" />
          </div>
        </template>
        <template>
          <el-popover
            ref="popover5"
            popper-class="popover"
            placement="bottom"
            trigger="hover"
          >
            <img class="qrcode" v-lazy="xboxQRUrl" />
            <!-- <div class="tips" style="
            position: absolute;bottom: -20px;left: 30px;color:rgba(0,0,0,0.3);font-size:12px;line-height:15px
            ">打开微信扫一扫</div> -->
          </el-popover>
          <div
            class="product-item"
            name="xbox"
            @mouseenter="productItemMouseEnter"
            @mouseleave="productItemMouseLeave"
          >
            <img v-popover:popover5 class="icon" v-lazy="xboxUrl" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { Popover } from 'element-ui'

export default {
  components: {
    [Popover.name]: Popover,
  },
  data() {
    return {
      selected: 'jump',
      jumpQRUrl:
        'http://switch-cdn.vgjump.com/img/qrcode/offical-20200116-zj-01-switch.jpeg?imageView2/0/format/jpg/q/75|imageslim',
      switchQRUrl:
        'http://switch-cdn.vgjump.com/img/qrcode/offical-20200116-zj-01-switch.jpeg?imageView2/0/format/jpg/q/75|imageslim',
      psQRUrl:
        'http://switch-cdn.vgjump.com/img/qrcode/offical-20200116-zj-01-ps.jpeg?imageView2/0/format/jpg/q/75|imageslim',
      steamQRUrl:
        'http://switch-cdn.vgjump.com/img/qrcode/offical-20200116-zj-01-steam.jpeg?imageView2/0/format/jpg/q/75|imageslim',
      xboxQRUrl:
        'http://switch-cdn.vgjump.com/img/qrcode/offical-20200116-zj-01-xbox.jpeg?imageView2/0/format/jpg/q/75|imageslim',
    }
  },
  computed: {
    steamUrl() {
      if (this.selected == 'steam') {
        return '/img/icon/steam.png'
      } else {
        return '/img/icon/steam-grey.png'
      }
    },
    jumpUrl() {
      if (this.selected == 'jump') {
        return '/img/icon/jump.png'
      } else {
        return '/img/icon/jump-grey.png'
      }
    },
    switchUrl() {
      if (this.selected == 'switch') {
        return '/img/icon/switch.png'
      } else {
        return '/img/icon/switch-grey.png'
      }
    },
    playstationUrl() {
      if (this.selected == 'playstation') {
        return '/img/icon/playstation.png'
      } else {
        return '/img/icon/playstation-grey.png'
      }
    },
    xboxUrl() {
      if (this.selected == 'xbox') {
        return '/img/icon/xbox.png'
      } else {
        return '/img/icon/xbox-grey.png'
      }
    },
  },
  methods: {
    productItemMouseEnter(e) {
      const a = e.target.getAttribute('name')
      this.selected = a
    },
    productItemMouseLeave() {
      // const a = e.target.getAttribute("name");
      // console.log(a)
      //this.selected = ''
    },
    productMouseLeave() {
      //this.selected = 'jump'
    },
  },
}
</script>
<style lang="scss">
.product {
  .popover {
    max-width: 6.5625vw;
  }
  & ::v-deep .tips {
    position: absolute;
    bottom: -20px;
    left: 25px;
  }
}
</style>
<style scoped lang="scss">
:focus {
  outline: 0;
}
.section {
  padding-top: 7.5rem !important;
  background: rgba($color: #ffffff, $alpha: 0) !important;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.index-page .page-header {
  height: 45vw;
}
.slogan p {
  color: #000000 !important;
  font-family: PingFang SC !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 2.5vw !important;
  line-height: 3.125vw !important;
  text-align: center !important;
}
.product {
  margin-top: 2.65vw;
  & ::v-deep .popover {
    max-width: 6.5625vw;
  }
  .qrcode {
    width: 6.5625vw;
  }

  .product-item {
    display: inline;
    margin-right: 1.667vw;
    .icon {
      width: 2.917vw;
    }
  }
}
</style>
