<template>
  <div>
    <index-background></index-background>
    <div class="page-header clear-filter" filter-color="jump">
      <div class="container">
        <basic-index></basic-index>
      </div>
    </div>
    <basic-discount></basic-discount>
    <basic-game></basic-game>
    <basic-post></basic-post>
  </div>
</template>
<script>
import BasicIndex from './components/BasicIndex.vue'
import IndexBackground from './components/IndexBackground.vue'
import BasicDiscount from './components/BasicDiscount.vue'
import BasicGame from './components/BasicGame.vue'
import BasicPost from './components/BasicPost.vue'

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    BasicIndex,
    IndexBackground,
    BasicDiscount,
    BasicGame,
    BasicPost,
  },
}
</script>
<style scope lang="scss">
.navbar {
  a {
    vertical-align: middle;
    &:not(.btn):not(.dropdown-item) {
      color: #020202;
    }
  }
}
</style>
