<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {
  mounted() {
    if (this._isMobile()) {
      if (this.$route.path !== '/m') {
        this.$router.replace('/m')
      }
    } else {
      if (this.$route.path !== '/') {
        this.$router.replace('/')
      }
      //this.$router.replace('/');
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
  },
}
</script>
<style>
/*for IE*/

:focus {
  outline: none;
}

/*for mozilla*/

::-moz-focus-inner {
  border-color: transparent;
}
</style>
