<template>
  <navbar
    class="navbar"
    position="fixed"
    type="white"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto custom-navbar"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img
          width="100"
          height="28"
          src="http://switch-cdn.vgjump.com/img/jump/offical-20200116-zj-01-jump_logo.png"
        />
      </router-link>
    </template>
    <template slot="navbar-menu">
      <router-link v-popover:popover1 class="navbar-brand" :to="indexPage">
        首页
      </router-link>
      <router-link v-popover:popover1 class="navbar-brand" :to="aboutPage">
        关于我们
      </router-link>
      <span class="navbar-brand active router-link-active">
        <!-- https://space.bilibili.com/321899908 -->
        <a
          href="https://mp.weixin.qq.com/s/AjKYHkmDWQXWbNnX8UrRTw"
          target="_black"
        >
          <img
            class="icon"
            src="http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-winxin.png"
          />
        </a>
      </span>
      <span class="navbar-brand active router-link-active">
        <a href="https://space.bilibili.com/321899908" target="_black">
          <img
            class="icon"
            src="http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-bilibili.png"
          />
        </a>
      </span>
      <span class="navbar-brand active router-link-active">
        <a href="https://www.zhihu.com/people/vgjump" target="_black">
          <img
            class="icon"
            src="http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-zhihu.png"
          />
        </a>
      </span>
      <span class="navbar-brand active router-link-active">
        <a href="https://weibo.com/6510377109/profile" target="_black">
          <img
            class="icon"
            src="http://switch-cdn.vgjump.com/img/icon/offical-20200116-zj-01-weibo.png"
          />
        </a>
      </span>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components'
import { Popover } from 'element-ui'

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  computed: {
    indexPage() {
      return this._isMobile() ? '/m' : '/'
    },
    aboutPage() {
      return this._isMobile() ? '/m/about' : '/about'
    },
  },
  components: {
    Navbar,
    [Popover.name]: Popover,
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
  },
}
</script>

<style scoped lang="scss">
.navbar-brand {
  .icon {
    width: 32px;
  }
}
</style>
