<template>
  <div class="section">
    <div class="container">
      <el-row :gutter="24">
        <el-col :span="24">
          <div class="about-us">
            <div class="about-us-item">
              <div class="title">商务合作</div>
              <div class="content">
                邮箱: business@vgjump.com
                <br />
                微信: PunkReed（张先生）
              </div>
            </div>
            <div class="about-us-item">
              <div class="title">游戏发行</div>
              <div class="content">
                邮箱：publish@cyberisle-inc.com
                <br />
                微信：PunkReed（张先生）
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="us-map">
            <img class="icon" v-lazy="mapUrl" />
          </div>
        </el-col>
        <el-col :span="24">
          <div class="about-us">
            <div class="about-us-item">
              <div class="title">加入我们</div>
              <div class="content">
                地址：北京市海淀区上地东路颐泉汇
                <br />
                邮箱：Job@vgjump.com
              </div>
            </div>
          </div>
        </el-col>
        <el-col>
          <el-button class="join" type="danger" round @click="toBoss">
            投递简历
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { Row, Col, Button } from 'element-ui'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
  },
  data() {
    return {
      mapUrl:
        'http://switch-cdn.vgjump.com/img/mobile/offical-20200116-zj-01-address.png',
    }
  },
  methods: {
    toBoss() {
      window, open('https://mp.weixin.qq.com/s/mASkcpwmgcxNkOjj12CPvw')
    },
  },
}
</script>
<style scoped lang="scss">
:focus {
  outline: 0;
}
.section {
  position: relative;
  padding: 6vw 0;
  .container {
    text-align: center;
    max-width: 92.5333vw;
    .about-us {
      .about-us-item {
        margin-top: 4vw;
        .title {
          padding: 0;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 4.8vw; //64px
          line-height: 6.72vw; // 81px
          color: #000000;
        }
        .content {
          margin-top: 4vw; //40px
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 3.2vw;
          line-height: 4.48vw;
          color: #000000;
        }
      }
    }
    .us-map {
      margin: auto;
      margin-top: 2.6667vw;
      .icon {
        width: 100vw;
        //height: 38.021vw;
      }
    }
    .join {
      margin-top: 4vw; //90px
      background-color: #e90000;
      border-color: #e90000;
      color: #ffffff;
    }
  }
}
</style>
